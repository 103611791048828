.org-header {
  color: #fff;
  line-height: 3rem;
}

button[data-cy="LoadFiles"] {
  --tw-bg-opacity: 1;
  background-color: rgb(9 68 179 / var(--tw-bg-opacity));
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
  font-size: 14px;
  font-weight: 500;
}

button[data-cy="LoadFiles"] div {
  width: max-content !important;
}

.progress-bar-container {
  width: 100%;
  background-color: #f3f3f3;
}

.progress-bar {
  width: 0;
  height: 30px;
  background-color: #4caf50;
}